<template>
  <b-card :title="event.name" style="height: 100%">
    <b-card-text>
      <p class="lead">
        {{ eventDate }}
      </p>
      <template v-if="event.site">
        <p class="mb-0">
          {{ event.site.name }}
        </p>
        <p class="text-muted address-line">
          {{ event.site.address.pretty }}
        </p>
      </template>
      <p v-else class="text-muted">Miesto konania ešte nie je uvedené!</p>

      <b-alert variant="warning" :show="invited">
        Si pozvaný/-á na toto sústredenie a ešte si nevyplnil/-a prihlášku.
      </b-alert>

      <b-alert variant="success" :show="accepted">
        Prihláška na sústredenie prijatá.
      </b-alert>

      <b-alert variant="danger" :show="declined">
        Prihláška na toto sústredenie bola odmietnutá.
      </b-alert>
    </b-card-text>

    <b-button
      :to="{ name: 'event-detail', params: { eventId: event.id } }"
      class="mt-auto"
    >
      Viac informácií
    </b-button>
  </b-card>
</template>

<script>
import { utils } from "frontend-common";
import constants from "@/constants";

export default {
  name: "EventSummary",
  components: {},
  mixins: [],
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    eventDate() {
      return utils.datesToDuration(this.event.start_date, this.event.end_date);
    },
    invited() {
      return (
        this.event.attendance_status === constants.attendance.status.INVITED &&
        new Date(this.event.registration_end_date) > new Date()
      );
    },
    accepted() {
      return (
        this.event.attendance_status === constants.attendance.status.ACCEPTED
      );
    },
    declined() {
      return (
        this.event.attendance_status === constants.attendance.status.DECLINED
      );
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped>
.address-line {
  font-size: 0.8em;
}

.card > .card-body {
  display: flex;
  flex-direction: column;
}
</style>
